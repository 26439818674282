import ServiceApi from '../ServiceApi';
/** Сервис для таблицы сводных данных сверок */
export default class ServiceReconBudgetTable {
    /**
     * Получить данные
     * @static
     * @async
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async getData(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('reconciliation', '/reconciliation/table/consolidated-table');
            const {data = {}} = res;
            const {data: dataTable = {}} = data;
            actionAfter(dataTable);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Получить данные
     * @static
     * @async
     * @property {Number} excangeId -номер бюджета
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async sendTerrasoft(excangeId, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('reconciliation', '/exchange/terrasoft/send', {exchange_id: excangeId});
            const {data = {}} = res;
            const {data: dataTable = {}} = data;
            actionAfter(dataTable);
        }
        catch(error) {
            console.log(error);
        }
    }
}
