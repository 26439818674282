const SchemePageBudget = {
    schemeTableColumns: [
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Подразделение",
            prop: "unit"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '150px'
            },
            label: "Клиент",
            prop: "client"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '150px'
            },
            label: "Подрядчик",
            prop: "supplier"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Юл подрядчика",
            prop: "supplier_entity"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Тип направления",
            prop: "type"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Направление",
            prop: "route"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '110px'
            },
            label: "Месяц",
            prop: "month"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '50px'
            },
            label: "Год",
            prop: "year"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '110px'
            },
            label: "Услуга",
            prop: "service"
        },
        /*{
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '110px'
            },
            label: "Город",
            prop: "city"
        },*/
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Название",
            prop: "name"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Бренд",
            prop: "brand"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Вид Договора",
            prop: "contractType"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '150px'
            },
            label: "Бюджет клиента (Выручка)",
            prop: "client_price"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '150px'
            },
            label: "Бюджет подрядчика (Себестоимость)",
            prop: "supplier_price"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '110px'
            },
            label: "Прибыль валовая",
            prop: "profit"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "Фио баера",
            prop: "buyer"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '180px'
            },
            label: "ФИО Руководителя",
            prop: "supervisor"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{},
            label: "Наличие НДС",
            prop: "tax"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '110px'
            },
            label: "Сверено поверхностей",
            prop: "revised"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '110px'
            },
            label: "Не сверено поверхностей",
            prop: "not_revised_count"
        },
        {
            isSlot: false,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '110px'
            },
            label: "Не создано поверхностей",
            prop: "not_found_count"
        },
        {
            isSlot: true,
            canSearch: false,
            canSorted: false,
            isModifiered: false,
            classes: [],
            columnStyles:{
                width: '120px'
            },
            label: "",
            prop: "send"
        }
        // {
        //     isSlot: true,
        //     canSearch: false,
        //     canSorted: false,
        //     isModifiered: false,
        //     classes: [],
        //     columnStyles:{
        //         width: '160px'
        //     },
        //     label: "Статус CRM",
        //     prop: "status"
        // }
    ],
    schemeStatusOptions: {
        0: {
            label: 'Ошибка',
            color: '#ff4343'
        },
        1: {
            label: 'Готово к отправке',
            color: '#284aff'
        },
        2: {
            label: 'Сверка',
            color: '#bababa'
        },
        3: {
            label: 'Отправлено',
            color: '#95ec58'
        }
    },
    exampleRows: [
        {
            id:1,
            month: 'Март',
            service: 'Аренда',
            client: 'Лексус',
            supplier: 'Русс',
            supplier_entity: 'ООО Русс',
            client_price: 5362000,
            supplier_price: 2356470,
            city: 'Москва',
            revised_count: 100,
            total_count: 100,
            status: 3
        },
        {
            id:2,
            month: 'Март',
            service: 'Печать',
            client: 'Лексус',
            supplier: 'Русс',
            supplier_entity: 'ООО Русс',
            client_price: 123042,
            supplier_price: 25000,
            city: 'Москва',
            revised_count: 100,
            total_count: 100,
            status: 1
        },
        {
            id:3,
            month: 'Март',
            service: 'Аренда',
            client: 'Вольво',
            supplier: 'Геллери',
            supplier_entity: 'ООО Геллери',
            client_price: 2141521,
            supplier_price: 1412513,
            city: 'СПб',
            revised_count: 56,
            total_count: 90,
            status: 2
        },
        {
            id:4,
            month: 'Март',
            service: 'Монтаж',
            client: 'Лексус',
            supplier: 'Геллери',
            supplier_entity: 'ООО Геллери',
            client_price: 1212512,
            supplier_price: 997645,
            city: 'СПб',
            revised_count: 56,
            total_count: 90,
            status: 0
        },
    ]
};
export default SchemePageBudget;
