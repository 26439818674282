export default {
    data: () => ({
        fuckingAuth: false,
    }),
    mounted() {
        this.fuckingAuth = localStorage.getItem('fuckingAuth') ? localStorage.getItem('fuckingAuth') : this.fuckingAuth;
    },
    methods: {
        onFuckingAuth() {
            this.fuckingAuth = true;
            localStorage.setItem('fuckingAuth', this.fuckingAuth);
        },
    }

};