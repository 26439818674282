<template>
        <div
            v-if="fuckingAuth"
            class="page-recon-edit"
        >
            <div>Всего {{totalCount}} отчетов, готово к отправке {{readyToSendCount}}</div>
            <div class="page-recon-edit__table-view">
                <div class="page-recon-edit__table-wrapper">
                    <AppTable
                        primaryKey="id"
                        :canCheck="false"
                        :canCheckAll="false"
                        :columns="columns"
                        :rows="rows"
                        :isProgressBar="isLoading"
                        class="all-table"
                    >
                        <template v-slot:status="scopeData">
                            <div class="status-cell">
                                <div :style="{'background-color': statusOptions[scopeData.row.status].color}" class="status-cell__color-box"/>
                                {{statusOptions[scopeData.row.status].label}}
                            </div>
                        </template>
                        <template v-slot:send="scopeData">
                            <base-button
                                v-if="!scopeData.row.not_found_count && !scopeData.row.not_revised_count"
                                view="secondary"
                                @click="onSentTerrasoft(scopeData)"
                            >
                                Отправить
                            </base-button>
                        </template>
                    </AppTable>
                </div>
            </div>
        </div>
        <div v-else class="page-recon-edit__container">
            <layout-reconciliation-auth @onFuckingAuth="onAuth"/>
        </div>
</template>

<script>
/** Компонент для страницы редактирования сверок */
import LayoutReconciliationAuth from '@/layouts/LayoutReconciliationAuth';
import BaseButton from "@/components/Base/BaseButton";
import {mapState} from "vuex";
import AppTable from "@/components/Table2/Table";
import MixinNumberModify from "@/mixins/MixinNumberModify";
import MixinReconciliationAuth from "@/mixins/MixinsReconciliation/MixinReconciliationAuth";
import ServiceReconBudgetTable from '@/services/ServicesReconBudget/ServiceReconBudgetTable';
import SchemePageBudget from '@/schemes/SchemePageBudget';
let {schemeTableColumns, schemeStatusOptions} = SchemePageBudget;
export default {
    name: 'PageReconciliationEdit',
    components: {
        LayoutReconciliationAuth,
        AppTable,
        BaseButton
    },
    mixins:[
        MixinNumberModify,
        MixinReconciliationAuth
    ],
    /**
     * Данные компонента
     * @property {Number} pageSize - размер страницы
     * @property {Number} pageNumber - номер текущие страницы
     * @property {Array} columns - колонки
     * @property {Array} rows - строки
     * @property {Boolean} isLoading - условие для отображения прелоадера
     */
    data: () => ({
        pageSize: 20,
        pageNumber: 0,
        columns: schemeTableColumns,
        rows: [],
        statusOptions: schemeStatusOptions,
        isLoading: false,
        totalCount: 0,
        readyToSendCount: 0
    }),
    computed: {
        /** Проксируем состояния из стора */
        ...mapState({
            filterSelectedData: state => state.filter.filterSelectedData,
        }),
        /**
         * Объект пагинации
         * @returns {Object}
         */
        pagination() {
            const pagination = {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber
            };
            return pagination;
        },
    },
    watch: {
        /** Следим за изменением фильтра и подтягиваем найдено */
        filterSelectedData() {
            /** При монтировании грузим таблицу и колличество*/
            if (this.fuckingAuth) {
                this.getTable();
            }
        },
    },
    mounted() {
        /** При монтировании грузим таблицу и колличество*/
        if (this.fuckingAuth) {
            this.getTable();
        }
    },
    methods: {
        getTable() {
            this.isLoading = true;
            this.readyToSendCount = 0;
            ServiceReconBudgetTable.getData((tableRows) => {
                tableRows.forEach(item => {
                    item.profit = this.numberFormat(Number(item.client_price) - Number(item.supplier_price), 0, '.', ' ');
                    item.client_price = this.numberFormat(Number(item.client_price), 0, '.', ' ');
                    item.supplier_price = this.numberFormat(Number(item.supplier_price), 0, '.', ' ');
                    item.revised = String(item.revised_count);/* + "/" + String(item.total_count);*/
                    if(!item.not_found_count && !item.not_revised_count){
                        this.readyToSendCount++;
                    }
                });
                this.totalCount = tableRows.length;
                this.rows = tableRows;
                this.isLoading = false;
            });
        },
        onAuth() {
            this.onFuckingAuth();
            this.getTable();
        },
        onSentTerrasoft(scopeData = {}){
            // this.isLoading = true;
            ServiceReconBudgetTable.sendTerrasoft(scopeData.row.id, (data) => {
                if(data.status === 'sent'){
                    alert('Данные отправлены');
                }
                // this.isLoading = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .status-cell{
        &__color-box {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            border-radius: 50%;
            display: inline-block;
        }
    }
    .page-recon-edit {
        height: 100%;
        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
        &__auth {
            width: 30%;
            &-input {
                margin-bottom: 20px;
            }
            &-title {
                line-height: 1;
                font-size: 16px;
                font-weight: 700;
                white-space: nowrap;
                text-align: center;
                margin-bottom: 20px;
            }
        }
        &__table {
            margin-top: 20px;
            padding-top: 40px !important;
            &-view {
                position: relative;
                height: 70%;
            }
            &-wrapper {
                height: 100%;
            }
        }
        &__nav {
            margin-bottom: 32px;
        }
        &__summary {
            margin-bottom: 32px;
        }
        &__control {
            margin-bottom: 15px;
        }
    }
</style>
