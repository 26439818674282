<template>
    <div class="layout-recon-auth">
        <p class="layout-recon-auth__title">
            Войти в систему
        </p>
        <base-text-input
            view="primary"
            placeholder="Логин"
            class="layout-recon-auth__input"
            @onInputLazy="onInputFuckingLogin"
        />
        <base-text-input
            view="primary"
            placeholder="Пароль"
            type="password"
            class="layout-recon-auth__input"
            @onInputLazy="onInputFuckingPassword"
        />
        <base-button
            view="secondary"
            @click="onFuckingAuth"
        >
            Войти
        </base-button>
    </div>
</template>

<script>
import BaseTextInput from '@/components/Base/BaseTextInput';
import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'LayoutReconciliationAuth',
    components: {
        BaseTextInput,
        BaseButton
    },
    data: () => ({
        fuckingLogin: 'root',
        fuckingPassword: 'root',
        fuckingLoginIn: '',
        fuckingPasswordIn: '',
    }),
    methods: {
        onInputFuckingLogin(value) {
            this.fuckingLoginIn = String(value);
        },
        onInputFuckingPassword(value) {
            this.fuckingPasswordIn = String(value)
        },
        onFuckingAuth() {
            if (this.fuckingLoginIn === this.fuckingLogin && this.fuckingPasswordIn === this.fuckingPassword)
                this.$emit('onFuckingAuth');
            else
                alert('Неправильный логин или пароль');
        }
    }

}
</script>

<style lang="scss" scoped>
    .layout-recon-auth {
        width: 30%;
        &__input {
            margin-bottom: 20px;
        }
        &__title {
            line-height: 1;
            font-size: 16px;
            font-weight: 700;
            white-space: nowrap;
            text-align: center;
            margin-bottom: 20px;
        }
    }
</style>